//@ts-nocheck
import React, { Component } from "react";
import { message, Modal, Popconfirm } from "antd";
import SwiftMessage from "../../../constants/message";
import {
  AMENDMENT_CREATED_STATUS,
  AMENDMENT_SUBMITTED_STATUS,
  AMENDMENT_RESUBMITTED_STATUS,
} from "../../../constants/payrun";
import _ from "lodash";
import AmendmentReviewTable from "./AmendmentReviewTable";
import RejectAmendments from "./rejectAmendments";
import {
  getConditionalResponse,
  getOptionalParameter,
  mustBeArray,
} from "../../../libs/utilities";
import SimpleButton from "../../../Common/ClaimComponents/Button/SimpleButton";
import classNames from "classnames";
import { getAmendmentStatusId } from "../../../libs";

type State = any;

class AmendmentsModal extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      confirmLoading: false,
      declineLoading: false,
      amendment: {},
      submitRejectionLoading: false,
      viewRejectModal: false,
      declineAll: false,
      title: "Are you sure you want to reject this amendment?",
    };
  }

  isCreatedOrSubmitted = (amendment: any) => {
    return [AMENDMENT_CREATED_STATUS, AMENDMENT_SUBMITTED_STATUS].includes(
      getAmendmentStatusId(amendment)
    );
  };

  getUnresolvedAmendments = () => {
    return _.filter([...this.props.amendmentSummary], (o) =>
      this.isCreatedOrSubmitted(o)
    );
  };

  approveAll = () => {
    this.setState({ confirmLoading: true });
    let unresolvedAmendments = this.getUnresolvedAmendments();
    unresolvedAmendments.forEach((amendment, i) => {
      this.props.approveAmendment({
        id: amendment.amendmentId,
        options: { payrunID: amendment.payrunId },
      });
    });
    this.setState({ confirmLoading: false });
  };

  declineAll = (record: any, description: any) => {
    this.setState({ declineLoading: true, submitRejectionLoading: true });
    let unresolvedAmendments = this.getUnresolvedAmendments();
    unresolvedAmendments.forEach((amendment) => {
      this.props.rejectPayrunAmendment({
        id: amendment.amendmentId,
        options: { payrunID: amendment.payrunId },
        data: { description, rejectionReasonID: 0 },
      });
    });
    this.setState({
      declineLoading: false,
      declineAll: false,
      viewRejectModal: false,
      submitRejectionLoading: false,
    });
  };

  checkActionAllDisabled = ({ type = "approve" }) => {
    const amendmentSummary = mustBeArray(this.props.amendmentSummary);
    if (
      _.find(
        amendmentSummary,
        (o) => getAmendmentStatusId(o) === AMENDMENT_RESUBMITTED_STATUS
      )
    )
      return true;
    if (!_.find(amendmentSummary, (o) => this.isCreatedOrSubmitted(o)))
      return true;
    return false;
  };

  rejectPayrunAmendment = (record: any, description: any) => {
    this.setState({ submitRejectionLoading: true });
    this.props.rejectPayrunAmendment({
      id: record.amendmentId,
      options: { payrunID: record.payrunId },
      data: { description, rejectionReasonID: 0 },
    });
    this.setState({
      viewRejectModal: false,
      amendment: {},
      submitRejectionLoading: false,
    });
    message.success(SwiftMessage["decline.success"]);
  };

  approveAmendment = (amendment: any, event: any) => {
    event.stopPropagation();
    this.props.approveAmendment({
      id: amendment.amendmentId,
      options: { payrunID: amendment.payrunId },
    });
    this.setState({ confirmLoading: false });
    message.success(SwiftMessage["approve.success"]);
  };

  handleDecline = (amendment: any, event: any) => {
    event.stopPropagation();
    this.setState(
      {
        amendment,
        title: "Are you sure you want to reject this amendment?",
      },
      () => {
        this.setState({ viewRejectModal: true });
      }
    );
  };

  onClose = () => {
    this.setState({
      viewRejectModal: false,
      declineAll: false,
      amendment: {},
      submitRejectionLoading: false,
    });
  };

  validateSubmitted = () => {
    return !_.isEmpty(
      _.filter(
        mustBeArray(this.props.amendmentSummary),
        (o) => Number(getAmendmentStatusId(o)) !== AMENDMENT_SUBMITTED_STATUS
      )
    );
  };

  handleDeclineAll = () => {
    this.setState(
      {
        declineAll: true,
        title: `Are you sure you want to reject ${
          this.validateSubmitted() ? "remaining" : "all"
        } amendments`,
      },
      () => this.setState({ viewRejectModal: true })
    );
  };

  render() {
    const {
      viewAmendmentModal,
      displaySubmitAmendment,
      handleSubmit,
      submitLoading,
    } = this.props;
    const { confirmLoading, declineLoading } = this.state;

    return (
      <React.Fragment>
        <Modal
          visible={viewAmendmentModal}
          className="collapseModal"
          title="Review Amendments"
          closable={true}
          onCancel={this.props.closeModal}
          width={"75vw"}
          footer={null}
        >
          <AmendmentReviewTable
            handleDecline={this.handleDecline}
            approveAmendment={this.approveAmendment}
            businessunitDetails={this.props.businessunitDetails}
            amendmentSummary={this.props.amendmentSummary}
          />

          <div className="flex mt-8">
            <React.Fragment>
              <Popconfirm
                placement="topLeft"
                key="approve"
                title={`Are you sure you want to approve ${this.validateSubmitted() ? "remaining" : "all"
                  } amendments?`}
                onConfirm={this.approveAll}
              >
                <SimpleButton
                  ignoreStatus
                  key="submit"
                  className={`mr-3 rounded-[20px] btn-rounded-[20px] ${this.checkActionAllDisabled({ type: "approve" })
                      ? ""
                      : "bg-success"
                    }`}
                  loading={confirmLoading}
                  buttonDisabled={this.checkActionAllDisabled({
                    type: "approve",
                  })}
                  buttonText={
                    this.validateSubmitted()
                      ? "Approve remaining"
                      : "Approve All"
                  }
                />
              </Popconfirm>
              <SimpleButton
                ignoreStatus
                key="back"
                className={`rounded-[20px] ${this.checkActionAllDisabled({ type: "decline" })
                    ? ""
                    : "bg-danger"
                  }`}
                loading={declineLoading}
                onClick={this.handleDeclineAll}
                buttonDisabled={this.checkActionAllDisabled({
                  type: "decline",
                })}
                buttonText={
                  this.validateSubmitted() ? "Reject remaining" : "Reject All"
                }
              />
            </React.Fragment>
            {getConditionalResponse({
              condition: getOptionalParameter({
                value1: displaySubmitAmendment,
                value2: !this.props.payrunSelected,
              }),

              resp1: (
                <SimpleButton
                  buttonDisabled={!this.props.allSubmitted}
                  ignoreStatus
                  buttonText="Submit"
                  onClick={handleSubmit}
                  className={classNames("ml-auto px-9", {
                    "bg-success": this.props.allSubmitted,
                  })}
                  loading={submitLoading}
                />
              ),

              resp2: <React.Fragment />,
            })}
          </div>
        </Modal>
        <RejectAmendments
          visible={this.state.viewRejectModal}
          cancelRejection={this.onClose}
          submitRejection={
            this.state.declineAll ? this.declineAll : this.rejectPayrunAmendment
          }
          amendment={this.state.amendment}
          submitRejectionLoading={this.state.submitRejectionLoading}
          title={this.state.title}
        />
      </React.Fragment>
    );
  }
}

export default AmendmentsModal;
