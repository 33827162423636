import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  amendmentIsAssociatedWithPayrun,
  findAuthorizationClaims,
  getAppliedFilters,
  getFilterLabel,
  getGeneralGridPersonalizations,
  getGeneralTablePersonalizations,
  getGridRequestOptions,
  getListContainerPersonalizations,
  getTableRequestOptions,
  getTargetEndpoint,
  getUpdateAmendmentPayloads,
  handleRequest,
  isComponentVisible,
  isEmpty,
  isUndefined,
  mustBeArray,
  setListContainerPersonalizationData,
  setTablePersonalizationData,
} from "../../../../libs";
import {
  selectFilterIsloading,
  selectFilters,
  setFilterLoading,
} from "../../../../slices/generalAmendment";
import { ListPersonalizationType } from "../../../../types";
import {
  generalAmendmentHandler,
  getGeneralAmendmentList,
  rejectGeneralAmendment,
} from "../../../actions/generalAmendment";
import DefaultListContainer from "../../common/ListContainerComponents/DefaultListContainer";
import PendingGeneralAmendmentTable from "../PendingGeneralAmendmentTable";
import axios from "axios";
import {
  useSliceActions,
  useSliceSelector,
} from "../../../../Common/SliceListProvider";
import {
  AMENDMENT_APPROVED_ONLY_STATUS,
  GENERAL_AMENDMENT_PENDING_LIST_CONTAINER,
  GENERAL_AMENDMENT_PENDING_TABLE_COMPONENT,
  GENERAL_AMENDMENT_PENDING_GRID_COMPONENT,
  REOCCURRING_STATUS_APPLIED_ID,
  APPROVE_SUCCESS_MESSAGE,
  REJECT_SUCCESS_MESSAGE,
} from "../../../../constants";
import {
  approveAmendment,
  deleteAmendment,
  updateAmendment,
  rejectPayrunAmendment,
} from "../../../actions/payrun";
import RejectAmendments from "../../../components/payrun/rejectAmendments";
import { savePersonalizations } from "../../../../User/actions/member";
import {
  selectAmendmentChangeTypeData,
  selectAmendmentRequestedByData,
  selectAmendmentTypeData,
} from "../../../../slices/lookups";

type Props = {
  pageClaims?: any;
  viewAmendmentDetail?: any;
  reloadPending: boolean;
  reloadCompleted: () => void;
};

const PendingGeneralAmendments = ({
  pageClaims, //DOM components status
  viewAmendmentDetail,
  reloadPending,
  reloadCompleted,
}: Props) => {
  const filters = useAppSelector(selectFilters);
  const filterIsloading = useAppSelector(selectFilterIsloading);
  const dispatch: any = useAppDispatch();
  const signal = axios.CancelToken.source();
  const [targetEndpoint, setTargetEndpoint] = useState("");
  const [filterApplied, setFilterApplied] = useState("");
  const [viewRejectModal, setViewRejectModal] = useState<boolean>(false);
  const [selectedAmendment, setSelectedAmendment] = useState<any>(null);
  const [amendmentRejectionLoading, setAmendmentRejectionLoading] =
    useState<boolean>(false);

  // Lookup data for amendment types and change types
  const changeTypeList = useAppSelector(selectAmendmentChangeTypeData);
  const amendmentTypeList = useAppSelector(selectAmendmentTypeData);
  const requestedByList = useAppSelector(selectAmendmentRequestedByData);

  // Get list state
  const pendingAmendmentState = useSliceSelector();

  // Get list actions
  const {
    initialPersonalization,
    dataLoaded,
    gridView,
    updateGrid,
    updateTable,
    setLoading,
  } = useSliceActions();

  useEffect(() => {
    if (isUndefined(pendingAmendmentState)) return;
    if (!pendingAmendmentState?.claimsUpdated) getInitialPersonalizations();
  }, [pendingAmendmentState?.claimsUpdated]);

  // Update filter applied and target endpoint
  const updateFilterAppliedAndTargetEndpoint = () => {
    // It might change depending on the change in filters
    const appliedFilters = getAppliedFilters(filters);
    const newAppliedFilters = getFilterLabel({
      ...appliedFilters,
      changeTypeList,
      amendmentTypeList,
      requestedByList,
    });
    setFilterApplied(newAppliedFilters);

    // Get target endpoint
    // It might change depending on the change in filters, table or grid component
    setTargetEndpoint(
      getTargetEndpoint({
        gridView: pendingAmendmentState?.container?.gridView,
        table: pendingAmendmentState?.table,
        grid: pendingAmendmentState?.grid,
        appliedFilters: appliedFilters,
        isPending: true,
      })
    );
  };

  // Get the initial sorting and pagination values from the claims
  const getInitialPersonalizations = () => {
    // List Personalization - grid or table view
    const listContainer = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: GENERAL_AMENDMENT_PENDING_LIST_CONTAINER,
    });
    const listPersonalization: ListPersonalizationType =
      getListContainerPersonalizations(listContainer);

    // Table personalizations
    const generalTable = findAuthorizationClaims({
      claims: listContainer?.components,
      name: GENERAL_AMENDMENT_PENDING_TABLE_COMPONENT,
    });
    const tablePersonalization: any =
      getGeneralTablePersonalizations(generalTable);

    // Grid personalizations
    const generalGrid = findAuthorizationClaims({
      claims: listContainer?.components,
      name: GENERAL_AMENDMENT_PENDING_GRID_COMPONENT,
    });
    const gridPersonalization: any =
      getGeneralGridPersonalizations(generalGrid);
    updateInitialClaimsAndPersonalizations({
      listPersonalization,
      gridPersonalization: {
        ...gridPersonalization,
        isVisible: isComponentVisible({ claim: generalGrid }),
      },
      tablePersonalization: {
        ...tablePersonalization,
        isVisible: isComponentVisible({ claim: generalTable }),
      },
    });
  };

  // Update state for initial claims and personalizations on page load
  const updateInitialClaimsAndPersonalizations = ({
    listPersonalization,
    gridPersonalization,
    tablePersonalization,
  }: {
    listPersonalization: ListPersonalizationType;
    gridPersonalization: any;
    tablePersonalization: any;
  }) => {
    // Update initial personalization
    initialPersonalization({
      container: { ...listPersonalization },
      table: { ...tablePersonalization },
      grid: { ...gridPersonalization },
    });
  };

  // Refetch the list when user toggles between grid and table view
  useEffect(() => {
    if (pendingAmendmentState?.claimsUpdated) {
      getData();
    }
  }, [
    pendingAmendmentState?.table,
    pendingAmendmentState?.grid,
    pendingAmendmentState?.container?.gridView,
  ]);

  useEffect(() => {
    if (filterIsloading && pendingAmendmentState?.claimsUpdated) {
      // If current page is higher than 1, reset the page to 1 while filtering
      if (
        !pendingAmendmentState?.container?.gridView &&
        pendingAmendmentState?.table.page !== 1
      ) {
        updateTable({ value: { page: 1 } });
      } else {
        // If the current pagination is 1, get the data
        getData();
      }
    }
  }, [filterIsloading]);

  // Reload data if an amendment is unapplied
  useEffect(() => {
    if (pendingAmendmentState?.claimsUpdated) {
      getData();
    }
  }, [reloadPending]);

  const getData = () => {
    let options: any = { page: 1, per_page: 20 };
    let userHasAccess: boolean = false;
    updateFilterAppliedAndTargetEndpoint();
    updateTablePersonalization();
    // If grid view and component is visible to the user fetch data
    if (
      pendingAmendmentState?.container?.gridView &&
      pendingAmendmentState?.grid?.isVisible
    ) {
      // Get grid current personalization settings
      options = getGridRequestOptions({ grid: pendingAmendmentState?.grid });
      userHasAccess = true;
    }
    // If table view and component is visible to the user fetch data
    if (
      !pendingAmendmentState?.container?.gridView &&
      pendingAmendmentState?.table?.isVisible
    ) {
      // Get Table current personalization settings
      options = getTableRequestOptions(pendingAmendmentState?.table);
      userHasAccess = true;
    }

    const appliedFilters = getAppliedFilters(filters);
    // Add applied filters
    options.filter = { ...appliedFilters };

    // Call the endpoint if user has access to view the component/data
    if (userHasAccess) {
      setLoading({ loading: true });
      dispatch(
        getGeneralAmendmentList({
          options: { ...options, filter: { ...options.filter, isPending: true } },
          cancelToken: signal.token,
        })
      ).then((response: any) => {
        setLoading({ loading: false });
        dispatch(setFilterLoading(false));
        if (handleRequest({ response, hasValidationErrors: true })) {
          handleDataAndPageVerification(
            mustBeArray(response?.data),
            response?.total
          );
        }
      });
    }
  };

  const updateTablePersonalization = () => {
    let personalizations = setTablePersonalizationData({
      pageNumber: pendingAmendmentState?.table?.page,
      pageSize: pendingAmendmentState?.table?.perPage,
      sortColumn: pendingAmendmentState?.table?.sortInfo?.field,
      sortOrder: pendingAmendmentState?.table?.sortInfo?.order,
    });

    let updatedComponent = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: GENERAL_AMENDMENT_PENDING_TABLE_COMPONENT,
    });
    // Breaks the DOM if updatedComponent doesn't exist or if there is an error
    if (updatedComponent?.id) {
      // Update Local DOM
      updateLocalDOM({
        personalizations,
        name: updatedComponent.name,
        id: updatedComponent.id,
      });
    }
  };

  const updateListContainerPersonalization = () => {
    let personalizations = setListContainerPersonalizationData({
      gridView: pendingAmendmentState?.container?.gridView,
      isPinned: false,
    });

    let updatedComponent = findAuthorizationClaims({
      claims: pageClaims?.components,
      name: GENERAL_AMENDMENT_PENDING_LIST_CONTAINER,
    });
    // Breaks the DOM if updatedComponent doesn't exist or if there is an error
    if (updatedComponent?.id) {
      updateLocalDOM({
        personalizations,
        name: updatedComponent.name,
        id: updatedComponent.id,
      });
    }
  };

  // Update local dom
  const updateLocalDOM = ({ personalizations, name, id }: any) => {
    dispatch(
      savePersonalizations({
        personalizations: [
          {
            value: personalizations,
            label: "personalizations",
            name,
            id,
          },
        ],
        update: !filterIsloading, //If filter is loading update will happen in completed amendment list
      })
    );
  };

  // Check if the user is not in the first page and the data is empty - change the page to 1 and re fetch the data
  const handleDataAndPageVerification = (data: any, total: number) => {
    if (isEmpty(data)) {
      if (
        pendingAmendmentState?.container?.gridView &&
        pendingAmendmentState?.grid?.page !== 1
      ) {
        // update the current page to 1
        return updateGrid({ value: { page: 1 } });
      }
      if (
        !pendingAmendmentState?.container?.gridView &&
        pendingAmendmentState?.table?.page !== 1
      ) {
        // update the current page to 1
        return updateTable({ value: { page: 1 } });
      }
    }
    dataLoaded({ data, total });
  };

  // LIST CONTAINER
  const handleGridViewChange = (view: boolean) => {
    if (pendingAmendmentState?.container?.gridView === view) return;
    gridView({ value: view });
    updateListContainerPersonalization();
  };

  // TABLE
  // On sorting Change
  const handleTableChange = (options: {
    sortInfo: any;
    page: number;
    perPage: number;
  }) => {
    updateTable({ value: options });
  };

  // GRID
  const handleGridChange = (options: {
    row: number;
    col: number;
    page: number;
    sortInfo: any;
  }) => {
    updateGrid({ value: options });
  };

  // Handle action events like view, edit and delete
  const handleGridTableAction = ({
    action,
    record,
  }: {
    action: string;
    record?: any;
  }) => {
    switch (action) {
      case "approve":
        return handleApproveAmendments(record);
      case "view":
        viewAmendmentDetail(record);
        break;
      case "delete":
        handleDelete(record);
        break;
      case "verifiedInKeypay":
        handleVerifiedInKeyPay(record);
        break;
      case "reject":
        setSelectedAmendment(record);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (selectedAmendment) {
      setViewRejectModal(true);
    }
  }, [selectedAmendment]);
  // Manually apply changes in keypay and update the status in application
  const handleVerifiedInKeyPay = (record: any) => {
    dispatch(
      updateAmendment({
        id: record?.amendmentId,
        values: {
          ...getUpdateAmendmentPayloads(record),
          amendmentStatusInPPSId: REOCCURRING_STATUS_APPLIED_ID,
        },
        payrunId: amendmentIsAssociatedWithPayrun({ amendment: record })
          ? record?.payrunId
          : null,
      })
    ).then((response: any) => {
      if (
        handleRequest({
          response,
          successMessage: "Amendment applied successfully.",
          hasValidationErrors: true,
        })
      ) {
        reloadCompleted();
        getData();
      }
    });
  };

  // Approve Amendment
  const handleApproveAmendments = (record: any) => {
    const successMessage = APPROVE_SUCCESS_MESSAGE;
    const reloadCompletedData = record?.isIntegrated;
    if (amendmentIsAssociatedWithPayrun({ amendment: record })) {
      dispatch(
        approveAmendment({
          id: record?.amendmentId,
          options: { payrunID: record.payrunId },
        })
      ).then((resp: any) => {
        handleResponse(resp, successMessage, reloadCompletedData);
      });
    } else {
      dispatch(
        updateAmendment({
          id: record?.amendmentId,
          values: {
            ...getUpdateAmendmentPayloads(record),
            amendmentStatusId: AMENDMENT_APPROVED_ONLY_STATUS,
          },
          payrunId: null,
        })
      ).then((response: any) => {
        handleResponse(response, successMessage, reloadCompletedData);
      });
    }
  };

  const handleResponse = (
    response: any,
    successMessage: string,
    reloadCompletedData = false
  ) => {
    if (
      handleRequest({
        response,
        successMessage,
        hasValidationErrors: true,
      })
    ) {
      if (successMessage === REJECT_SUCCESS_MESSAGE) {
        handleRejectModalClose();
      }
      if (reloadCompletedData) {
        reloadCompleted();
      }
      getData();
    }
  };

  // Delete Amendment
  const handleDelete = (data: any) => {
    const successMessage = "Amendment deleted successfully";
    if (amendmentIsAssociatedWithPayrun({ amendment: data })) {
      dispatch(
        deleteAmendment({
          id: data?.amendmentId,
          payrunId: data?.payrunId,
          options: {},
        })
      ).then((resp: { status: any; data: { validationErrors: any } }) => {
        handleResponse(resp, successMessage);
      });
    } else {
      dispatch(
        generalAmendmentHandler({
          id: data?.amendmentId,
          cancelToken: signal.token,
          action: "delete",
        })
      ).then((resp: { status: any; data: { validationErrors: any } }) => {
        handleResponse(resp, successMessage);
      });
    }
  };

  // Reject Amendment
  const handleReject = (record: any, description: string) => {
    setAmendmentRejectionLoading(true);
    const successMessage = REJECT_SUCCESS_MESSAGE;
    if (amendmentIsAssociatedWithPayrun({ amendment: record })) {
      //use payrun amendment endpoint
      dispatch(
        rejectPayrunAmendment({
          id: record.amendmentId,
          data: { description: description, rejectionReasonID: 0 },
          options: { payrunID: record.payrunId },
          cancelToken: axios.CancelToken,
        })
      ).then((resp: any) => {
        setAmendmentRejectionLoading(false);
        handleResponse(resp, successMessage, true);
      });
    } else {
      //use general amendment endpoint
      dispatch(
        rejectGeneralAmendment({
          id: record?.amendmentId,
          data: {
            description: description,
            rejectionReasonID: 0,
          },
        })
      ).then((resp: any) => {
        setAmendmentRejectionLoading(false);
        handleResponse(resp, successMessage, true);
      });
    }
  };

  const handleRejectModalClose = () => {
    setSelectedAmendment(null);
    setViewRejectModal(false);
  };
  return (
    <>
      <div className="bold text-md mt-2 mb-4">Pending Action</div>
      <DefaultListContainer
        pageClaims={pageClaims}
        name={GENERAL_AMENDMENT_PENDING_LIST_CONTAINER}
        container={pendingAmendmentState?.container}
        grid={pendingAmendmentState?.grid}
        table={pendingAmendmentState?.table}
        listLoading={pendingAmendmentState?.loading}
        filterApplied={filterApplied}
        handleAction={handleGridTableAction}
        handleGridChange={handleGridChange}
        handleGridViewChange={handleGridViewChange}
        handleTableChange={handleTableChange}
        tableComponentName={GENERAL_AMENDMENT_PENDING_TABLE_COMPONENT}
        gridComponentName={GENERAL_AMENDMENT_PENDING_GRID_COMPONENT}
        total={pendingAmendmentState?.total}
        componentTitle="Pending Amendments"
        GeneralTablePaginatedComponent={PendingGeneralAmendmentTable}
        targetEndpoint={targetEndpoint}
        rowIdParam="amendmentId"
        data={pendingAmendmentState?.data}
        hasNoDuplicateEntity
      />
      <RejectAmendments
        visible={viewRejectModal}
        cancelRejection={handleRejectModalClose}
        submitRejection={handleReject}
        amendment={selectedAmendment}
        submitRejectionLoading={amendmentRejectionLoading}
        maxCharacters={2048}
      />
    </>
  );
};

export default PendingGeneralAmendments;
